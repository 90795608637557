<!--商品添加-->
<template>
    <div class="mainBox">
        <!--Header-->
        <div class="handleBox flex flex-jc-sb flex-ai-c">
            <el-page-header @back="goBack" :content="title"></el-page-header>
            <el-button v-if="!isShow" type="primary" size="mini" block @click="submit()">{{ submitTitle }}</el-button>

        </div>
        <!--form表单-->
        <div class="formBox shopBox">
            <el-tabs v-model="activeName" style="padding: 0 20px; box-sizing: border-box">
                <el-tab-pane label="添加信息" name="first"></el-tab-pane>
            </el-tabs>

            <!--商品信息-->
            <el-row style="height: calc(100% - 70px); overflow: auto">
                <el-form v-if="activeName == 'first'" ref="formAuth" :rules="formRules" :model="formData" label-width="120px" label-position="right" size="small" class="alertForm">
                    <el-form-item label="商品选择" prop="range">
                        <el-select v-if="update" filterable v-model="formData.range" placeholder="请选择" style="width: 100%">
                            <el-option v-for="item in catelist" :key="item.id" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="" prop=""></el-form-item>
                    <el-form-item label="价格" prop="sku" style="width:100%">
                        <div v-for="(val , id) in formData.sku" :key="id">
                            <el-input v-model="val.price" placeholder="价格" autocomplete="off" :disabled="isShow" style="width:200px;margin:0 10px 10px 0;"></el-input>
                            <el-select v-model="val.years" clearable placeholder="使用期限" :disabled="isShow" style="width:200px;margin:0 10px 10px 0;">
                                <el-option v-for="(item, index) in yearAry" :key="index" :label="item.name" :value="item.value"></el-option>
                            </el-select>
                            <el-button v-if="id == 0" type="primary" size="mini" @click="addItem">添加</el-button>
                            <el-button v-else type="primary" size="small" @click="reduceItem(id)">删除</el-button>
                        </div>
                    </el-form-item>
                    <el-form-item label="主图(145*65)" prop="image" style="width:100%">
                        <uploadImage :imageList="formData.image" :limitNum="limit" :clickFile="'image'" @upload="uploadFile" :mayEdit="isShow"></uploadImage>
                    </el-form-item>
                    <el-form-item label="会员提成金额" prop="staff_balance">
                        <el-input v-model="formData.staff_balance" placeholder="提成金额" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item class="hidden"></el-form-item>
                    <el-form-item label="会员续费提成">
                        <el-input v-model="formData.staff_balance_next" placeholder="提成金额" autocomplete="off"></el-input>
                    </el-form-item>
                    
                    <el-form-item label="是否上架" style="width:100%">
                        <el-switch v-model="formData.is_show" :active-value="1" :inactive-value="0" @change="setupSwitch($event)"></el-switch>
                    </el-form-item>
                </el-form>
            </el-row>
        </div>
    </div>
</template>
<script>
import uploadImage from '@/components/uploadImage'
import {
    catereadApi,
    cateListeditApi,
    yearsApi,
    shopListApi,
} from '@/api/store.js'
export default {
    data() {
        return {
            title: '', //标题
            submitTitle: '保存',
            activeName: 'first', //状态
            cateAry: [
                {
                    id: 1,
                    name: '单品类',
                },
                {
                    id: 2,
                    name: '多品类',
                },
            ],
            yearAry: [],
            formRules: {
                name: [
                    {
                        required: true,
                        message: '请输入会员名称',
                        trigger: 'change',
                    },
                ],
                cate_id: [
                    {
                        required: true,
                        message: '请输入会员分类',
                        trigger: 'change',
                    },
                ],
                range: [
                    {
                        required: true,
                        message: '请选择商品',
                        trigger: 'change',
                    },
                ],

                sku: [
                    {
                        required: true,
                        message: '请填写价格',
                        trigger: 'change',
                    },
                ],

                years: [
                    {
                        required: true,
                        message: '请填写年限',
                        trigger: 'change',
                    },
                ],
                // image: [
                // 	{
                // 		required: true,
                // 		message: '请上传商品主图',
                // 		trigger: 'blur',
                // 	},
                // ],
            },
            formData: {
                id: 0,
                cate_id: 1,
                image: [],
                sku: [],
                range: '',
                is_show: 1,
            }, //详情数据
            isShow: false, //详情or编辑
            catelist: [],
            sing_show: false,
            db_show: false,
            detail: [],
            swich: '',

            limit: 1, //商品主图上传个数限制

            update: true, //
        }
    },
    components: {
        uploadImage, //图片上传
    },
    mounted() {
        this.init()
    },
    watch: {
        'formData.cate_id'() {
            this.update = false
            setTimeout(() => {
                this.update = true
            })
        },
        deep: true, //对象内部的属性监听，深度监听
    },
    methods: {
        addItem: function () {
            this.formData.sku.push({
                price: '',
                years: '',
            })
        },
        reduceItem: function (k) {
            this.formData.sku.splice(k, 1)
        },
        //初始化数据
        init: function () {
            let _this = this
            this.title = this.$route.query.title //标题
            this.isShow = JSON.parse(this.$route.query.isShow) //编辑or查看
            this.selectList()

            if (_this.$route.query.id) {
                _this.formData.id = _this.$route.query.id
                _this.getStoreDetail()
            } else {
                this.addItem()
            }

            this.years()
        },

        years: function () {
            yearsApi().then((response) => {
                for (var x in response) {
                    this.yearAry.push({
                        name: response[x].name,
                        value: response[x].day,
                    })
                }
            })
        },

        //获取商品基本信息
        getStoreDetail: function () {
            catereadApi({
                id: this.$route.query.id,
            }).then((response) => {
                // console.log('id', response)
                this.formData = response
                if (response.cate_id == 1) {
                    this.formData.range = parseInt(response.range)
                } else {
                    let arr = []
                    for (var x in response.cate) {
                        arr.push(response.cate[x].id)
                    }
                    this.formData.range = arr
                }
                //商品图片展示格式
                let image = []
                for (let item of response.image) {
                    image.push({
                        url: item,
                    })
                }
                //商品主图
                this.formData.image = image

            })
        },
        //下拉列表
        selectList: function () {
            shopListApi({ row: 100, series_id: 40 }).then((response) => {
                // this.selectData = response;
                this.catelist = response.list
            })
        },

        setupSwitch: function (val) { },
        
        //返回上一页
        goBack() {
            this.$router.go(-1)
        },

        // 单多品类
        changeOne() {
            if (this.formData.cate_id == 2) {
                this.formData.range = []
            } else {
                this.formData.range = ''
            }
        },

        //接收上传图片返回数据
        uploadFile: function (file) {
            if (file.image) {
                this.formData.image = file.image
            } else {
                this.formData.slider_image = file.slider_image
            }
        },

        /************************************提交**********************************/

        //保存商品
        submit: function () {
            this.$refs['formAuth'].validate((valid) => {
                if (valid) {
                    cateListeditApi(this.formData).then(() => {
                        this.goBack()
                    })
                }
            })
        },
    },
}
</script>
<style lang="less">
.noShowClass {
    width: 100% !important;
    margin-right: 0;
}
.isShowClass {
    width: calc(98% - 123px) !important;
    margin-right: 2%;
}
.shopDes {
    .editor {
        width: 375px;
        margin: 0;
        .w-e-toolbar .w-e-menu {
            padding: 5px 7px;
        }
    }
}
</style>
